<div id="home" class="container-fluid vh-100 px-0 mx-0">
    <app-nav></app-nav>

    <section class="row flex-column mx-0 d-flex justify-content-center first bg-4 vh-100">
        <div class="col-10 col-sm-6 bg-light text-dark rounded mx-auto d-flex">
            <div class="my-auto align-self-center text-center py-4">
              <h1 class="mat-display-1 title my-auto">Mapping the landscape of Arts in Health and Well-being in the context of Children and Youth in South Africa</h1>
              <p class="mt-4">Researchers: Malika Ndlovu, Marlize Swanepoel, Sunelle Fouché, Andeline dos Santos, Dominik Havsteen-Franklin and Cara Smith.</p>
              <div class="text-center w-100 mb-4">
                <button mat-flat-button class="mt-4" (click)="scrollToElement('intro')">
                  <span class="material-icons">arrow_drop_down</span>
                  INTRODUCTION
                </button>
              </div>
            </div>
      </div>
    </section>

    <section id="intro" class="row second bg-2 flex-column mx-0 justify-content-center vh-100 py-4">
        <div class="col-11 col-sm-6 bg-light text-dark rounded mx-auto">
            <div class="mx-auto mat-typography">
              <h1 class="my-4 mat-display-1 title">Introduction and background to the project</h1>
              <p>In 2017, a Cape Town-based NPO, MusicWorks, initiated the coordination of a network of arts-based organizations that uses the arts to offer psychosocial support in South African communities. The purpose of the community of practice (CoP) is to serve as a platform for connection, sharing, collaborating, advocating and lobbying for the Arts in Health in South Africa. In 2019, The Learning Trust mandated the CoP with a research project that investigates arts-based psychosocial programmes for children and youth in South Africa. UNICEF, University of Pretoria, Brunel University and the National Department of Sports, Arts and Culture are all contributors towards this effort to map the current landscape of the Arts in Health and well-being practice with children and youth in South Africa.</p>
              <br/>
              <b >Aims of the research</b>
              <ol>
                <li>To define the concepts of arts and health from an indigenous knowledge perspective.</li>
                <li>To produce a map of the landscape of Arts in Health practices with children and youth in South Africa.</li>
                <li>To advocate for an Arts in Health agenda in South Africa.</li>
              </ol>
              <br/>
              <b>Key questions asked</b>
              <ol>
                <li>How are the concepts of arts and health defined in a South African context?</li>
                <li>What is known from the existing literature about arts programmes and the health and well-being of children and youth in South Africa?</li>
                <li>How do South African service providers identify and describe the arts programmes they deliver?</li>
                <li>What is the relationship between published literature and experience on the ground.</li>
              </ol>
              <div class="text-center w-100 mb-4">
                <button mat-flat-button class="mt-4 mx-auto" (click)="scrollToElement('methods')">
                  <span class="material-icons">arrow_drop_down</span>
                  METHODS
                </button>
              </div>
            </div>
      </div>
    </section>


    <section id="methods" class="row second bg-3 flex-column mx-0 vh-100 justify-content-center py-4">
        <div class="col-11 col-sm-6 bg-light text-dark rounded mx-auto">
            <div class="mx-auto mat-typography">
              <h1 class="my-4 mat-display-1 title">Methods</h1>
              <p>A mixed methods design was chosen that includes focus groups, arts-based participatory research, concept analysis and a scoping review:</p>
              <ul>
              <li>Stage 1: A priority setting group to set the research agenda. </li>
              <li>Stage 2: A concept analysis to surface contextual and indigenous knowledge.</li>
              <li>Stage 3: An arts-based focus group to co-create new knowledge.</li>
              <li>Stage 4: A scoping review to map Arts in Health practices with children and youth in South Africa.</li>
              </ul>
              <div class="text-center w-100 mb-4">
                <button mat-flat-button class="mt-4 mx-auto" (click)="scrollToElement('learnings')">
                  <span class="material-icons">arrow_drop_down</span>
                  LEARNINGS
                </button>
              </div>
            </div>
      </div>
    </section>


    <section id="learnings" class="row second bg-white flex-column mx-0 vh-100 justify-content-center py-4">
        <div class="col-11 col-sm-6 bg-light text-dark rounded mx-auto">
            <div class="mx-auto mat-typography">
              <h1  class="my-4 mat-display-1 title">What we’ve learned so far – a square does not fit into a circle</h1>
              <p>
                The researchers position themselves within a transformative and post-colonial paradigm that seeks a mutual undertaking with an indigenous paradigm. Held (2019) is of the opinion that radically decolonizing research means that any decolonizing research paradigm must be developed conjointly between Western and Indigenous researchers, creating a new research framework altogether.
</p>
<p>
  An initial broad overview of the literature brought Fancourt and Finn’s (2019) scoping review entitled “What is the evidence on the role of the arts in improving health and well-being?” to our attention. It focused on the Northern Hemisphere and we decided to offer it as a potential frame for this study to a focus group in South Africa to explore how the concepts and definitions were understood and engaged with in a local context. Participants felt strongly that the Western informed frame of Arts in Health concepts does not translate in an African context:
</p>

<p style="border-left: 4px solid #ccc" class="ml-2 pl-2">
  <i>“We are in Africa, why are we using European standards? Your point of departure needs to be redefining it from an African perspective.”</i><br/> (Emile Jansen, Heal the Hood Project & Black Noise )
</p>

<p style="border-left: 4px solid #ccc" class="ml-2 pl-2">
<i>“The irony of it is most of the information that Europe has claimed has actually been taken from African information.”</i><br/> (Emile Jansen, Heal the Hood Project & Black Noise )
</p>

<p class="mt-4">
  We saw that the surfacing of knowledge can be an experience of discomfort that invites both the colonizer and the colonized to co-create a new way of knowing as opposed to forcing a fit of indigenous knowledge in a Western frame. (Held, 2019, Keikelame and Swartz, 2018 ) The focus group participants agreed:
</p>
<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“I think these boxes replicate what the [Western] art world does, which if you think of an exhibition, it happens in a box in a museum, in an art gallery, in a theatre and it has created spaces that excludes, so what's missing is that we're not considering the community as an environment for art or creation and for expression and it excludes community engagement, it excludes community activation.”</i>
<br/>(Firdous Hendricks, Lalela)
</p>
<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“Where I learned musical stuff was literally from just the community members teaching back to the community.”</i> <br/>(Sanelisiwe Dlamini, Drama For Life, Honours DT Student)
</p>
<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“It (the Fancourt & Finn model) says culture, for example going to museums, galleries, exhibitions, concerts, and theatre. I don't know,  I think from a South African perspective going to museums and galleries is great, but it's a privilege that unfortunately a lot of our children don't have.” (Sanelisiwe Dlamini, Drama For Life, Honours DT Student)</i>
</p>

<p class="mt-4">
The problematic role of language as it defines a concept was also highlighted:
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“Terminology like ‘specialized’ actually excludes more than includes.”</i><br/> (Emile Jansen, Heal the Hood Project & Black Noise )
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“Using terminologies like having a ‘special’ skill deters more than bring people into the circle.”</i><br/>(Emile Jansen, Heal the Hood Project & Black Noise )
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“Managing [health] sounds quite clinical compared to what we actually do in our work.”</i><br/> (Tessa Whyatt, Art Therapist)
</p>

<p class="mt-4">
And finally, we learned that the arts offer us a helpful way to navigate these uncomfortable experiences of co-creating knowledge by connecting with each other through image and metaphor to find a shared language:
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
  <i>“I had an image of a kalabash. And for me, that is what came to mind when I was thinking about an image that represents the work I'm doing.”</i> Phumzile Rakosa, Educator and Community Art Facilitator.
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“The image that came to mind was a kalabash like a water pitcher that dips into a very broad and flowing river and then goes out to empty onto the many places that need it, whether that be audiences, whether it be students, whether it be people who are having therapy, wherever that may be.”</i> (Kerryn, violin teacher and music therapist)
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“So it was like two hands holding a space, building a container, like a bowl, because I feel that it is most important for people to have a space to be in, to feel in, to create in and to heal in.”</i> <br/>(Angela Katschke, Butterfly Art Project)
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“And the image that I thought of was ukhamba, it's basically like a bowl or like some sort of kalabash, but it usually has like umqombothi, Zulu beer, in it. And I just like the way when there are celebrations, it's just passed on from one person to another. So I'm just getting this image of these hands, taking ukhamba. The connectedness, the unity that it creates in that space.”</i><br/>(Sanelisiwe Dlamini, Drama For Life, Honours DT Student)
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“Yeah, so I've got this object. This to me (holds up a mug) is symbolic of the work we're doing. So we create this vessel that can hold our boiling water and then we can put our Wilde Als (African wormwood) or Buchu or Rooibos in there and mix it up and make our brew and we can drink it and we can share it and we can all heal from it.  We need a vessel that can hold us. Then we can pour our life energy, our water that's boiling because we are so full of creativity. It's boiling hot. And then we can bring all our indigenous herbs or our cultures, and we can mix them up in there and we can heal from this tea.”</i><br/> (Vincent, Jungle Theatre Company)
</p>

<p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
<i>“I also had an image of fire and heat and I'm sitting outside and I'm looking straight into a three legged potjie (cast iron pot) and for me that's the symbol, the ability to absorb heat and you can chuck anything in it. And in most cases what will emerge is something very tasteful even if you just have a bit of salt and pepper. And I've been thinking a lot of the work we do with children, women, and youth, how that helps to enhance the resilience of the people we work with. And I'm just thinking how resilient the potjie is to stand these high temperatures of heat. And often it requires very little and it provides for so many people as opposed to just a traditional pot.”</i><br/>(Raymond Schuller, MusicWorks)
</p>

          <div class="text-center w-100 mb-4">
            <button mat-flat-button  class="mt-4 mx-auto" (click)="scrollToElement('wayforward')">
              <span class="material-icons">arrow_drop_down</span>
              WAY FORWARD
            </button>
          </div>
        </div>
      </div>
    </section>

    <section id="wayforward" class="row second bg-1 flex-column mx-0 vh-100 justify-content-center py-4">
        <div class="col-11 col-sm-6 bg-light text-dark rounded mx-auto">
              <div class="mx-auto mat-typography">
                <h1 class="my-4 mat-display-1 title">The way forward</h1>
                <p style="border-left: 4px solid #ccc"  class="ml-2 pl-2">
                <i>“It's like bringing the water into a very arid landscape in which we are silenced even by the more visible or entrenched bodies of knowledge. And this is about collapsing that structure. This wealth of creativity can be a gift from South Africa to the world, not South Africa's version of what the world elsewhere has given us.”</i><br/> Malika Ndlovu, Writer, poet, applied arts practitioner.
                </p>

                <p class="mt-4">Essentially we learned that the collective, cyclical nature of Africanism (Held, 2019 and Dapice, 2006) does not resonate with the Western linear approach to Arts in Health. We needed to go right back to the drawing board in the planning of this study. Following these priority setting groups, we took a hermeneutic loop and applied a concept analysis to the transcribed interviews, as well as existing literature, to excavate an indigenous definition of concepts around the arts and health in South Africa. The concept analysis is currently underway and once we have collated the themes from both the transcripts and the literature, we will present them once again to participants in an experiential, arts-based process of engaging with these ideas. </p>
                <div class="text-center w-100 mb-4">
                  <button mat-flat-button  class="mt-4 mx-auto" (click)="scrollToElement('home')">
                    <span class="material-icons">arrow_drop_up</span>
                    HOME
                  </button>
                </div>
              </div>
        </div>
    </section>



    <div class="row justify-content-center mx-0 pt-4">
      <div class="col-6">
        <p>UNICEF partnered with the University of Pretoria to scale up various skill-building and capacities to respond to COVID-19 with a focus on youth, especially in consideration of the global project Generation Unlimited (GenU). This project has been made possible by the UNICEF funded the Youth Empowerment and Health/Economic Responses to COVID-19 (YEaH).</p>
      </div>
    </div>

    <div class="row justify-content-center mx-0">
      <div class="col-3">
        <img src="/assets/unicef-logo.jpg" class="img-fluid" />
      </div>
    </div>

    <footer class="row justify-content-center mx-0 py-4 text-right">
      <div class="col-6">
        &copy; 2021 Arts For Health South Africa
        <span class="mx-2">|</span>
        <a href="mailto:sunelle@musicworks.org.za">Sunelle Fouché</a>
        <span class="mx-2">|</span>
        <a href="mailto:info@marlize.co.za">Marlize Swanepoel</a>
      </div>
    </footer>
</div>
