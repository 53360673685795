<nav class="navbar navbar-expand-lg navbar-light bg-light  flex-shrink-0">
  <a class="navbar-brand" href="#"><span class="title">Arts For Health South Africa</span></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">

    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#" (click)="scrollToElement('home')">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" (click)="scrollToElement('intro')">Introduction</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" (click)="scrollToElement('methods')">Methods</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" (click)="scrollToElement('learnings')">Learnings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" (click)="scrollToElement('wayforward')">Way FOrward</a>
      </li>
    </ul>
  </div>
</nav>
