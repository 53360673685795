import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'arts-for-health';

  constructor()
  {
  }
  scrollToElement(id): void {
    $("html, body").animate({ scrollTop: $('#' + id).offset().top }, 1000);
  }
}
